import React from "react"
import { Link } from "gatsby"
import "../components/layout.css"

const GetEarlyAccess = () => {
  return (
    <div className="w-full h-screen gradient md:p-16">
      <h1 className="text-center text-white">Get early access</h1>
      <p className="text-center text-white">
        Buy explot and start exploring your data.
      </p>
      <form
        method="post"
        name="buy-eary-access-step-1"
        className="w-4/5 md:w-2/3  max-w-lg mx-auto shadow-custom p-8 rounded bg-white"
        action="/checkout/"
      >
        <input
          type="hidden"
          className="hidden"
          name="form-name"
          value="buy-eary-access-step-1"
        />
        <label className="md:w-2/3 block text-gray-500 font-bold mr-3 px-2 mb-8">
          <input
            className="mr-2 leading-tight"
            name="buy-early-access"
            type="checkbox"
          />
          <span className="text-sm">
            Buy early access to explot for a one time payment of $49.
          </span>
        </label>
        <Link
          to="/checkout/"
          className="py-2 flex-shrink-0 bg-dark-green hover:shadow-custom border-dark-green hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
        >
          Continue to checkout
        </Link>
        <Link
          to="/"
          className="flex-shrink-0 border-transparent border-4 text-green text-sm py-1 px-2 rounded"
        >
          Cancel
        </Link>
      </form>
    </div>
  )
}

export default GetEarlyAccess
